<template>
  <v-dialog v-model="show" persistent width="700">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT KHU VỰC HẢI QUAN" : "THÊM KHU VỰC HẢI QUAN"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã khu vực HQ</div>
              <v-text-field
                v-model="form.code"
                placeholder="Mã khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="codeRules"
                :disabled="edit ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Tên khu vực HQ</div>
              <v-text-field
                v-model="form.name"
                placeholder="Tên khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Mã trên TK</div>
              <v-text-field
                v-model="form.sku"
                placeholder="Mã trên TK"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <div class="label-form">Tên chi cục</div>
              <v-text-field
                v-model="form.deparment_name"
                placeholder="Tên khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Tên đội</div>
              <v-text-field
                v-model="form.team_name"
                placeholder="Tên khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <div class="label-form">Mã đội</div>
              <v-text-field
                v-model="form.team_code"
                placeholder="Tên khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Thứ tự</div>
              <v-text-field
                v-model="form.order"
                placeholder="Tên khu vực"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { addCustomsArea, editCustomsArea } from "@/api/khuvuchaiquan";
export default {
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      code: null,
      name: null,
      deparment_name: null,
      team_name: null,
      team_code: null,
      order: 0,
      description: null,
    },
    nameRules: [(v) => !!v || "Tên khu vực HQ không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã khu vực HQ không thể bỏ trống"],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        code: null,
        name: null,
        deparment_name: null,
        team_name: null,
        team_code: null,
        order: 0,
        description: null,
      };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await addCustomsArea(this.form).then((res) => console.log(res));
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Thêm khu vực thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await editCustomsArea(this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
